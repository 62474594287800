import React from "react"
import { Typography, Box } from "@material-ui/core"
import PropTypes from "prop-types"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    sectionContainer: {
      marginBottom: theme.spacing(20),
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
      [theme.breakpoints.up("md")]: {
        padding: 0,
      },
    },
  })
})

const SectionContainer = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <Box classes={{ root: classes.sectionContainer }} {...rest}>
      {children}
    </Box>
  )
}

SectionContainer.propTypes = {
  children: PropTypes.node,
}

export default SectionContainer
